export const sygnet = [
  '160 160',
  `
  <circle
     style="opacity:0.2;fill:#000000;stroke-width:0;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:5;stroke-dashoffset:3.28027;paint-order:stroke fill markers"
     id="path1"
     cx="80"
     cy="80"
     r="65" />
  <g
     id="layer1"
     transform="matrix(3.7795276,0,0,3.7795276,-699.00015,-582.00001)"
     style="stroke-width:0.264583">
    <g
       id="g253"
       transform="matrix(0.26458333,0,0,0.26458333,1226.8139,-470.63948)"
       style="stroke-width:0.264583">
      <rect
         style="fill:none;fill-opacity:1;stroke-width:0;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:5;stroke-dashoffset:3.28027;paint-order:stroke fill markers"
         id="rect253"
         width="160"
         height="160"
         x="-3937.7769"
         y="2360.7949" />
      <path
         id="path214-4"
         clip-path="url(#clipPath215-4-0)"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.38226"
         d="M -6.3234377e-4,487.05529 C -69.278563,487.05529 -132.17551,459.25247 -178.15347,414.23351 h -117.47901 l 67.65674,-68.26318 c -16.40298,-32.74897 -25.9449,-69.49693 -26.7539,-108.35889 -0.049,-1.785 -0.0806,-3.57319 -0.0806,-5.36719 0,-37.33996 8.0754,-72.82947 22.56738,-104.81543 C -192.20287,39.057905 -103.16353,-22.565328 -6.3234377e-4,-22.565328 100.15827,-22.565328 186.99033,35.529346 228.60728,119.78526 c 16.76499,33.93897 26.20313,72.11403 26.20313,112.45899 0,3.96299 -0.0904,7.98924 -0.27539,11.96924 -1.707,36.89996 -11.27743,72.40299 -27.79541,104.50195 -0.009,0.016 -0.0174,0.0343 -0.0264,0.0513 l 72.93164,65.4668 H 178.32652 c -0.712,0.697 -1.41474,1.4008 -2.13574,2.0918 C 128.54983,461.93626 65.977302,487.05529 -6.3234377e-4,487.05529 Z M 170.24205,337.77013 c 12.13632,0.30653 25.09736,-1.02096 38.93848,-4.25684 13.26698,-27.45697 21.31401,-57.98134 22.81201,-90.34131 0.167,-3.63299 0.25195,-7.30973 0.25195,-10.92773 C 232.24449,104.18438 128.06024,5.9062501e-4 -6.3234377e-4,5.9062501e-4 -128.0605,5.9062501e-4 -232.24283,104.18438 -232.24283,232.24425 c 0,1.449 0.0272,2.89001 0.0542,4.33301 1.125,44.69395 19.19098,87.69734 24.66797,99.75732 109.727893,13.96699 161.969543,-76.23099 186.124519,-149.69092 l -22.463379,-21.42187 41.314454,-66.287115 0.6987305,-1.12207 0.021973,0.03809 c 0.002,-0.019 0.00439,-0.03809 0.00439,-0.03809 0,0 0.00186,0.01873 0.00586,0.05273 l 0.666504,1.069336 41.3144535,66.287119 -21.637207,20.63232 C 40.796619,253.44779 85.287767,335.6244 170.24205,337.77013 Z m -319.07374,-27.37354 c -33.11997,0 -59.96924,-26.85073 -59.96924,-59.9707 0,-33.11997 26.84927,-59.96924 59.96924,-59.96924 33.11997,0 59.970704,26.84927 59.970704,59.96924 0,33.11997 -26.850734,59.9707 -59.970704,59.9707 z m 293.97071,0 c -33.12097,0 -59.970705,-26.85073 -59.970705,-59.9707 0,-33.11997 26.849735,-59.96924 59.970705,-59.96924 33.11997,0 59.96924,26.84927 59.96924,59.96924 0,33.11997 -26.84927,59.9707 -59.96924,59.9707 z"
         transform="matrix(0.25534374,0,0,-0.25509172,-3858.2892,2500.0387)" />
    </g>
  </g>
`,
]
