import React from 'react'

import './AnimatedLogo.css'
import Logo from './Logo'

const AnimatedLogo = () => {
  return <Logo width="164" height="200" />
}

export default React.memo(AnimatedLogo)
