import React from 'react'
import { t } from 'i18next'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Questions = React.lazy(() => import('./views/student/questions/Questions'))
const MyBooks = React.lazy(() => import('./views/student/myBooks/MyBooks'))
const MockTests = React.lazy(() => import('./views/student/mockTests/MockTests'))
const MyStudyPlans = React.lazy(() => import('./views/student/myStudyPlans/MyStudyPlans'))
const MyPerformance = React.lazy(() => import('./views/student/myPerformance/MyPerformance'))
const MyStudents = React.lazy(() => import('./views/manager/myStudents/MyStudents'))
const StudyGroups = React.lazy(() => import('./views/manager/studyGroups/StudyGroups'))
const Reports = React.lazy(() => import('./views/manager/reports/Reports'))
const Subscription = React.lazy(() => import('./views/user/subscription/Subscription'))

const routes = [
  { path: '/', exact: true, name: t('Home') },
  { path: '/dashboard', name: t('Dashboard'), element: Dashboard },
  { path: '/questions', name: t('Questions'), element: Questions },
  { path: '/my-books', name: t('My Books'), element: MyBooks },
  { path: '/mock-tests', name: t('Mock Tests'), element: MockTests },
  { path: '/my-plans', name: t('My Study Plans'), element: MyStudyPlans },
  { path: '/my-performance', name: t('My Performance'), element: MyPerformance },
  { path: '/manager/my-students', name: t('My Students'), element: MyStudents },
  { path: '/manager/study-groups', name: t('Study Groups'), element: StudyGroups },
  { path: '/manager/reports', name: t('Reports'), element: Reports },
  { path: '/subscription', name: t('Subscription'), element: Subscription },
]

export default routes
