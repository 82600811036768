import axios from 'axios'
import { getToken } from './auth'
import i18n from 'i18next'

const apiBaseURL = 'https://api.testeme.com.br'
// const apiBaseURL = 'http://localhost:8000'

export const api = axios.create({
  baseURL: `${apiBaseURL}`,
})

export const getManifestURL = (subdomain) => {
  return `${apiBaseURL}/api/v1/subdomain/${subdomain}/manifest/`
}

export const userLogin = (academy, slug, password) => {
  const url = '/api/v1/token/'
  return api.post(
    url,
    {
      academy: academy,
      slug: slug,
      password: password,
    },
    { headers: { 'Accept-Language': `${getBrowserLanguage()}` } },
  )
}

export const getSubdomainConfig = (subdomain) => {
  const url = `/api/v1/subdomain/${subdomain}/`
  return api.get(url, {
    headers: {
      'Accept-Language': `${getBrowserLanguage()}`,
    },
  })
}

export const getProfile = () => {
  const url = '/api/v1/profile/'
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Accept-Language': `${getBrowserLanguage()}`,
    },
  })
}

export const createS3Url = (data) => {
  const url = '/api/v1/upload/'
  return api.post(url, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Accept-Language': `${getBrowserLanguage()}`,
    },
  })
}

export const getQuestions = (search, page) => {
  const url = `/api/v1/question/?search=${search}&page=${page}`
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Accept-Language': `${getBrowserLanguage()}`,
    },
  })
}

export const getAuthors = (search, page) => {
  const url = `/api/v1/options/author/?search=${search}&page=${page}`
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Accept-Language': `${getBrowserLanguage()}`,
    },
  })
}

export const getBoards = (search, page) => {
  const url = `/api/v1/options/board/?search=${search}&page=${page}`
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Accept-Language': `${getBrowserLanguage()}`,
    },
  })
}

export const getTests = (search, page) => {
  const url = `/api/v1/options/test/?search=${search}&page=${page}`
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Accept-Language': `${getBrowserLanguage()}`,
    },
  })
}

export const getFields = (search, page) => {
  const url = `/api/v1/options/field/?search=${search}&page=${page}`
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Accept-Language': `${getBrowserLanguage()}`,
    },
  })
}

const getBrowserLanguage = () => {
  return i18n.language
}

export default api
