import { t } from 'i18next'
import { FileUploadErrorNames } from './types'
import { createS3Url } from '../api'
import axios from 'axios'

export class FileUploadError extends Error {
  name
  details

  constructor(name, details) {
    super(undefined)

    this.name = name
    this.message = this.getErrorMessage()
    this.details = details || { status: 400, error: null }

    Object.setPrototypeOf(this, FileUploadError.prototype)
  }

  getErrorMessage() {
    switch (this.name) {
      case FileUploadErrorNames.UPLOAD_FAILED:
        return t('An error occurred when uploading file')
      case FileUploadErrorNames.UPLOAD_CANCELED:
        return t('Upload has been canceled')
      case FileUploadErrorNames.NO_COMMON_DIRECTORY:
        return t('Loaded files should belong to a common root directory')
      case FileUploadErrorNames.CREATE_S3_URL_FAILED:
        return t('An error occurred while creating file path on storage')
      default:
        return t('Unknown error')
    }
  }
}

export class FileUpload {
  totalFiles
  cancelTokenSource
  progressSum
  progress
  uploaded
  canceled

  constructor() {
    this.cancelTokenSource = null
    this.progressSum = 0
    this.totalFiles = 1
    this.progress = 0
    this.uploaded = false
    this.canceled = false
  }

  send(uploadData, module, progressCallback) {
    const data = { module }

    return new Promise((resolve, reject) => {
      if (uploadData instanceof File) {
        data.file = uploadData
        this.sendFile(data, progressCallback, resolve, reject)
        return
      }

      reject(new FileUploadError(FileUploadErrorNames.NO_COMMON_DIRECTORY))
    })
  }

  cancel() {
    if (this.cancelTokenSource) {
      this.canceled = true
      this.progress = 0
      this.progressSum = 0
      this.cancelTokenSource.cancel()
    }
  }

  handleProgress(event, progressCallback) {
    if (event.loaded !== undefined && event.total !== undefined) {
      this.progress = Math.floor((event.loaded / event.total) * 100)
    }

    if (progressCallback) {
      progressCallback((this.progressSum + this.progress) / this.totalFiles)
    }
    if (event.loaded === event.total) {
      this.progressSum += this.progress
    }
  }

  uploadFile(url, file, progressCallback) {
    this.progress = 0
    this.progressSum = 0
    this.uploaded = false
    this.canceled = false

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line import/no-named-as-default-member
      this.cancelTokenSource = axios.CancelToken.source()

      axios
        .put(url, file, {
          onUploadProgress: (event) => {
            this.handleProgress(event, progressCallback)
          },
          cancelToken: this.cancelTokenSource.token,
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          if (this.canceled) {
            reject(
              new FileUploadError(FileUploadErrorNames.UPLOAD_CANCELED, {
                status: 400,
                error,
              }),
            )
            return
          }

          reject(
            new FileUploadError(FileUploadErrorNames.UPLOAD_FAILED, {
              status: 403,
              error,
            }),
          )
        })
    })
  }

  handleCreateS3Url(data) {
    const { module, file } = data
    const name = file ? file.name : ''
    const content_type = file?.type

    return new Promise((resolve, reject) => {
      createS3Url({ module, name, content_type })
        .then((response) => resolve(response.data))
        .catch((error) =>
          reject(
            new FileUploadError(FileUploadErrorNames.CREATE_S3_URL_FAILED, {
              status: error && error.response ? error.response.status : 500,
              error,
            }),
          ),
        )
    })
  }

  sendFile(data, progressCallback, resolve, reject) {
    this.handleCreateS3Url(data)
      .then((response) => {
        const { url, pk, path } = response

        this.uploadFile(url, data?.file, progressCallback, false)
          .then(() => {
            this.uploaded = true
            this.cancelTokenSource = null

            resolve({
              pk,
              path: path,
              totalFiles: this.totalFiles,
              progress: this.progress,
              uploaded: this.uploaded,
              canceled: this.canceled,
              moduleId: pk,
              fileUrl: `${url}/`,
            })
          })
          .catch((error) => reject(error))
      })
      .catch((error) => reject(error))
  }
}
