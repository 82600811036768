import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { CContainer } from '@coreui/react-pro'

// routes config
import routes from '../routes'
import Loading from './Loading'
import AppBreadcrumb from './AppBreadcrumb'
import { useDispatch } from 'react-redux'

const AppContent = ({ eventHandler, loadingHandler, toastHandler, errorHandler }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      dispatch({ type: 'set', breadcrumbActions: [] })
    }
  }, [navigate])

  return (
    <CContainer lg>
      {/* <AppBreadcrumb /> */}
      <Suspense fallback={<Loading />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={
                    <route.element
                      eventHandler={eventHandler}
                      loadingHandler={loadingHandler}
                      toastHandler={toastHandler}
                      errorHandler={errorHandler}
                    />
                  }
                />
              )
            )
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
          <Route path="*" element={<Navigate to="404" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

AppContent.propTypes = {
  eventHandler: PropTypes.func,
  loadingHandler: PropTypes.func,
  toastHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

export default React.memo(AppContent)
