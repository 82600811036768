import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  CImage,
  CNavItem,
  CNavTitle,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from '@coreui/react-pro'

import { AppSidebarNav } from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import {
  cilBook,
  cilCalendar,
  cilChartLine,
  cilCreditCard,
  cilEducation,
  cilGrain,
  cilHome,
  cilMoney,
  cilPencil,
  cilPeople,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import './AppSidebar.css'
import { useTranslation } from 'react-i18next'
import { sygnet } from 'src/assets/brand/sygnet'
import { logoNegative } from 'src/assets/brand/logo-negative'

const AppSidebar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const logo = useSelector((state) => state.subdomainConfig.logo)
  const logoRounded = useSelector((state) => state.subdomainConfig.logoRounded)

  const navigation = {
    main: [
      {
        component: CNavItem,
        name: t('Home'),
        to: '/dashboard',
        icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
      },
      {
        component: CNavTitle,
        name: t('Questions'),
      },
      {
        component: CNavItem,
        name: t('Resolve questions'),
        to: '/questions',
        icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: t('My books'),
        to: '/my-books',
        icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: t('Mock tests'),
        to: '/mock-tests',
        icon: <CIcon icon={cilEducation} customClassName="nav-icon" />,
      },
      {
        component: CNavTitle,
        name: t('Study plans'),
      },
      {
        component: CNavItem,
        name: t('My plans'),
        to: '/my-plans',
        icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: t('My performance'),
        to: '/my-performance',
        icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
      },
      {
        component: CNavTitle,
        name: t('Management'),
      },
      {
        component: CNavItem,
        name: t('My students'),
        to: '/manager/my-students',
        icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: t('Study groups'),
        to: '/manager/study-groups',
        icon: <CIcon icon={cilGrain} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: t('Reports'),
        to: '/manager/reports',
        icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: t('Subscription'),
        to: '/subscription',
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
    ],
    bottom: [
      // {
      //   component: CNavItem,
      //   name: t('Profile'),
      //   to: '/profile',
      //   icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      // },
    ],
  }

  return (
    <CSidebar
      colorScheme="light"
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        {logo ? (
          <CImage className="sidebar-brand-full" src={logo} height={35} />
        ) : (
          <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        )}
        {logoRounded ? (
          <CImage className="sidebar-brand-narrow" src={logoRounded} height={35} />
        ) : (
          <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
        )}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar style={{ height: '100%' }}>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
