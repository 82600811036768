import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react-pro'
import Flag from './Flag'

const I18nDropdown = ({ type }) => {
  const { t, i18n } = useTranslation()
  const [currentLang, setCurrentLang] = useState(null)

  const selectLanguage = (code) => {
    i18n.changeLanguage(code)
    setCurrentLang(code)
  }

  useEffect(() => {
    setCurrentLang(i18n.language)
  }, [])

  return (
    <CDropdown variant="btn-group">
      <CDropdownToggle
        color="light"
        variant="outline"
        className={type}
        size="sm"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Flag title={''} language={currentLang} width={24} height={24} className="mr-2" />
      </CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem
          onClick={() => selectLanguage('en')}
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          <Flag title={''} language={'en'} width={24} height={24} />
          {t('English')}
        </CDropdownItem>
        <CDropdownItem
          onClick={() => selectLanguage('pt')}
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          <Flag title={''} language={'pt'} width={24} height={24} />
          {t('Português')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

I18nDropdown.propTypes = {
  type: PropTypes.string,
}

export default React.memo(I18nDropdown)
