import React from 'react'
import PropTypes from 'prop-types'
import { US, BR, ES, PT } from 'country-flag-icons/react/3x2'

const Flag = ({ title, language, height, width }) => {
  const flags = {
    en: US,
    'en-US': US,
    es: ES,
    pt: BR,
    'pt-PT': PT,
  }

  const FlagComponent = flags[language] || US
  return (
    <FlagComponent
      title={title}
      height={height}
      width={width}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />
  )
}

Flag.propTypes = {
  title: PropTypes.string,
  language: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default React.memo(Flag)
