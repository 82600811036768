import React from 'react'
import { useLocation } from 'react-router-dom'

import routes from '../routes'

import { CBreadcrumb, CBreadcrumbItem, CButton } from '@coreui/react-pro'
import { useSelector } from 'react-redux'

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname
  const breadcrumbActions = useSelector((state) => state.breadcrumbActions)

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, routes)
      routeName &&
        breadcrumbs.push({
          pathname: `#${currentPathname}`,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <>
      <div className="d-flex flex-column  flex-md-row align-items-center justify-content-between">
        <div>
          <div className="fs-2 fw-semibold">{[...breadcrumbs].pop().name}</div>
          <CBreadcrumb className="mb-4">
            <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
            {breadcrumbs.map((breadcrumb, index) => {
              return (
                <CBreadcrumbItem
                  {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
                  key={index}
                >
                  {breadcrumb.name}
                </CBreadcrumbItem>
              )
            })}
          </CBreadcrumb>
        </div>
        <div className="actions d-flex mt-2 mb-2">
          {breadcrumbActions.map((action, index) => {
            return (
              <CButton
                key={index}
                className="ms-1"
                color={action.color}
                onClick={action.onClick}
                size="sm"
              >
                {action.label}
              </CButton>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default React.memo(AppBreadcrumb)
