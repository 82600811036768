import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CImage,
  CNav,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'

import './AppHeader.css'

import { AppHeaderDropdown } from './header/index'

import { logo } from 'src/assets/brand/logo'

const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const logoCustom = useSelector((state) => state.subdomainConfig.logo)

  return (
    <CHeader position="sticky" className="mb-4" style={{ height: '74px' }}>
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-flex ms-0 d-md-none" to="/">
          {logoCustom ? (
            <CImage className="p-0" src={logoCustom} height={48} alt="Logo" />
          ) : (
            <CIcon className="p-0" icon={logo} height={48} alt="Logo" />
          )}
        </CHeaderBrand>
        <CNav>
          <CHeaderNav>
            <AppHeaderDropdown />
          </CHeaderNav>
        </CNav>
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
