import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getManifestURL, getSubdomainConfig } from './services/api'
import { Error, Loading } from './components'
import { useTranslation } from 'react-i18next'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

const App = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showError, setShowError] = useState(false)
  const [showLoading, setShowLoading] = useState(true)
  const subdomain = useSelector((state) => state.subdomain)

  const errorHandler = (show) => {
    setShowError(show)
  }

  const setCSSVariable = (className, variableName, value) => {
    let styleSheet = document.styleSheets[0]
    let rule
    for (let i = 0; i < styleSheet.cssRules.length; i++) {
      if (styleSheet.cssRules[i].selectorText === className) {
        rule = styleSheet.cssRules[i]
        break
      }
    }
    if (!rule) {
      styleSheet.insertRule(`${className} {}`, 0)
      rule = styleSheet.cssRules[0]
    }

    rule.style.setProperty(variableName, value)
  }

  const updateFavicon = (rel, href, sizes = '') => {
    if (href) {
      let link = document.querySelector(`link[rel="${rel}"]${sizes ? `[sizes="${sizes}"]` : ''}`)
      if (!link) {
        link = document.createElement('link')
        link.rel = rel
        if (sizes) link.sizes = sizes
        document.head.appendChild(link)
      }
      link.href = href
    }
  }

  const updateMeta = (name, content) => {
    if (content) {
      let meta = document.querySelector(`meta[name="${name}"]`)
      if (!meta) {
        meta = document.createElement('meta')
        meta.name = name
        document.head.appendChild(meta)
      }
      meta.content = content
    }
  }

  const updateColors = (colors) => {
    setCSSVariable(':root', '--cui-primary', colors.primary)
    setCSSVariable(':root', '--cui-primary-rgb', colors.primary_rgb)
    setCSSVariable(':root', '--cui-link-color', colors.primary)
    setCSSVariable(':root', '--cui-link-color-rgb', colors.primary_rgb)
    setCSSVariable(':root', '--cui-link-hover-color', colors.primary_darker_30)
    setCSSVariable(':root', '--cui-link-hover-color-rgb', colors.primary_darker_30_rgb)
    setCSSVariable(':root', '--cui-input-focus-border-color', colors.primary)
    setCSSVariable(
      '.form-control:focus',
      'box-shadow',
      `0 0 0 0.25rem rgb(${colors.primary_lighter_80_rgb})`,
    )
    setCSSVariable('.sidebar-light', '--cui-sidebar-brand-bg', colors.primary)
    setCSSVariable('.sidebar-light', '--cui-sidebar-nav-link-hover-bg', colors.primary)
    setCSSVariable('.sidebar-light', '--cui-sidebar-nav-link-active-icon-color', colors.primary)

    setCSSVariable('.btn-primary', '--cui-btn-bg', colors.primary)
    setCSSVariable('.btn-primary', '--cui-btn-border-color', colors.primary)
    setCSSVariable('.btn-primary', '--cui-btn-hover-bg', colors.primary_lighter_30)
    setCSSVariable('.btn-primary', '--cui-btn-hover-border-color', colors.primary_lighter_30)
    setCSSVariable('.btn-primary', '--cui-btn-disabled-bg', colors.primary)
    setCSSVariable('.btn-primary', '--cui-btn-disabled-border-color', colors.primary)
  }

  useEffect(() => {
    const hostname = window.location.hostname
    const segments = hostname.split('.')
    let subdomain = 'app'

    if (segments.length > 2) {
      subdomain = segments[0]
    }
    dispatch({ type: 'set', subdomain: subdomain })
  }, [dispatch])

  useEffect(() => {
    if (subdomain) {
      const manifestLink = document.querySelector('link[rel="manifest"]')
      if (manifestLink) {
        manifestLink.href = getManifestURL(subdomain)
      }

      getSubdomainConfig(subdomain)
        .then((response) => {
          dispatch({
            type: 'set',
            subdomainConfig: {
              showAcademyField: response.data.login_input_academy,
              logo: response.data.logo,
              logoRounded: response.data.logo_rounded,
              academy: response.data.academy,
            },
          })

          updateFavicon('apple-touch-icon', response.data.apple_icon ?? 'apple-touch-icon.png')
          updateFavicon('icon', response.data.favicon_32 ?? 'favicon-32x32.png', '32x32')
          updateFavicon('icon', response.data.favicon_16 ?? 'favicon-16x16.png', '16x16')
          updateFavicon('shortcut icon', response.data.favicon ?? '_favicon.ico')

          const title = response.data.page_title ?? 'teste-me'
          document.title = title
          updateMeta('description', title)
          updateMeta('keyword', title)

          const appName = response.data.app_name ?? 'teste-me'
          updateMeta('apple-mobile-web-app-title', appName)
          updateMeta('application-name', appName)

          if (response.data.colors) {
            updateColors(response.data.colors)
          }
        })
        .catch((err) => {
          setShowError(true)
        })
        .finally(() => {
          setShowLoading(false)
        })
    }
  }, [subdomain])

  useEffect(() => {
    setShowLoading(true)
  }, [])

  return (
    <>
      <HashRouter>
        {showError ? (
          <Error text={t('Oops! Something went wrong.')} errorHandler={errorHandler} />
        ) : null}
        {showLoading ? (
          <Loading />
        ) : (
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        )}
      </HashRouter>
    </>
  )
}

export default App
