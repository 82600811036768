import React from 'react'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { CButton } from '@coreui/react-pro'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { error } from 'src/assets/images/error'

const Error = ({ text, errorHandler }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const reload = () => {
    errorHandler(false)
    navigate(0)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexFlow: 'column',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          zIndex: '99999',
          backgroundColor: 'var(--cui-body-bg)',
        }}
      >
        <CIcon icon={error} height={200} />
        <p className="h4 mt-4 mb-4 p-0">{text}</p>
        <CButton onClick={() => reload()}>{t('Reload')}</CButton>
      </div>
    </>
  )
}

Error.propTypes = {
  errorHandler: PropTypes.func,
  text: PropTypes.string,
}

export default React.memo(Error)
