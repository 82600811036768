import { createStore } from 'redux'

const initialState = {
  breadcrumbActions: [],
  subdomain: '',
  subdomainConfig: {
    showAcademyField: false,
    logo: null,
    logoRounded: null,
    academy: null,
  },
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
