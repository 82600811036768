import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge, CNavGroup, CNavItem } from '@coreui/react-pro'
import './AppSidebarNav.css'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CIcon from '@coreui/icons-react'
import { cilChatBubble } from '@coreui/icons'

export const AppSidebarNav = ({ items }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const profile = useSelector((state) => state.profile)

  const getRecentsItems = () => {
    let items = []
    // for (let recent of profile.recents) {
    //   items.push({
    //     component: CNavItem,
    //     className: 'app-sidebar',
    //     name: recent.title,
    //     to: `/events/${recent.id}`,
    //   })
    // }
    if (items.length > 0) {
      return [
        {
          component: CNavGroup,
          name: t('Recents'),
          to: '/base',
          icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
          items: items,
        },
      ]
    } else {
      return []
    }
  }

  const navLink = (name, icon, badges) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badges && (
          <div className="d-flex align-items-center justify-content-center ms-auto">
            {badges.map((badge, index) => (
              <CBadge size="sm" key={index} color={badge.color} className="ms-1">
                {badge.text}
              </CBadge>
            ))}
          </div>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badges, icon, ...rest } = item
    const Component = component
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badges)}
      </Component>
    )
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <>
      <React.Fragment>
        <div className="nav-menu">
          <div className="nav-menu-main">
            <>
              {items &&
                items.main.map((item, index) =>
                  item.items ? navGroup(item, index) : navItem(item, index),
                )}
              {getRecentsItems().length > 0
                ? getRecentsItems().map((item, index) =>
                    item.items ? navGroup(item, index) : navItem(item, index),
                  )
                : null}
            </>
          </div>
          <div className="nav-menu-bottom">
            {items &&
              items.bottom.map((item, index) =>
                item.items ? navGroup(item, index) : navItem(item, index),
              )}
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.objectOf(PropTypes.any).isRequired,
}
