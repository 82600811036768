import React from 'react'
import PropTypes from 'prop-types'

function Logo({ width, height, className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M-299.107 17.951h595.276v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="b" clipPathUnits="userSpaceOnUse">
          <path d="M-134.032 119.893h595.276v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="c" clipPathUnits="userSpaceOnUse">
          <path d="M-462.261 188.23h595.276v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="d" clipPathUnits="userSpaceOnUse">
          <path d="M-133.014 188.23h595.276v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="e" clipPathUnits="userSpaceOnUse">
          <path d="M-298.193 308.736h595.276V-286.54h-595.276Z" />
        </clipPath>
        <clipPath id="f" clipPathUnits="userSpaceOnUse">
          <path d="M-302.007 159.649h595.276v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="g" clipPathUnits="userSpaceOnUse">
          <path d="M-501.579 179.244H93.697v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="h" clipPathUnits="userSpaceOnUse">
          <path d="M-537.441 57.928H57.835v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="i" clipPathUnits="userSpaceOnUse">
          <path d="M-471.776 57.928H123.5v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="j" clipPathUnits="userSpaceOnUse">
          <path d="M-504.608 57.928H90.668v-595.276h-595.276Z" />
        </clipPath>
        <clipPath id="k" clipPathUnits="userSpaceOnUse">
          <path d="M-57.835 57.928h595.276v-595.276H-57.835Z" />
        </clipPath>
        <clipPath id="l" clipPathUnits="userSpaceOnUse">
          <path d="M-123.5 57.928h595.276v-595.276H-123.5Z" />
        </clipPath>
        <clipPath id="m" clipPathUnits="userSpaceOnUse">
          <path d="M-90.668 57.928h595.276v-595.276H-90.668Z" />
        </clipPath>
      </defs>
      <g
        style={{
          strokeWidth: 3.9685,
        }}
        transform="matrix(.25198 0 0 .25198 27.1 -18.334)"
      >
        <path
          d="M228.509 517.113c0 47.542 163.254 270.331 113.67 325.415-16.833 18.7-94.297 25.014-153.237 1.546-35.33-14.068-128.856-57.741-168.773-125.175-37.024-62.546-59.967-94.604-65.205-213.183-3.162-71.604 67.522-118.38 140.823-118.38 73.3 0 132.722 58.104 132.722 129.777z"
          style={{
            fill: '#fff',
            strokeWidth: 0,
            strokeLinecap: 'square',
            strokeLinejoin: 'round',
            strokeMiterlimit: 5,
            strokeDashoffset: 3.28027,
            paintOrder: 'stroke fill markers',
          }}
        />
        <ellipse
          cx={217.873}
          cy={343.016}
          rx={260.025}
          ry={259.087}
          style={{
            fill: '#fff',
            strokeWidth: 0,
            strokeLinecap: 'square',
            strokeLinejoin: 'round',
            strokeMiterlimit: 5,
            strokeDashoffset: 3.28027,
            paintOrder: 'stroke fill markers',
          }}
        />
        <g
          style={{
            strokeWidth: 1.05,
          }}
        >
          <path
            d="M0 0c-101.867 0-184.74-82.874-184.74-184.741 0-101.867 82.873-184.741 184.74-184.741 101.866 0 184.741 82.874 184.741 184.741 0 2.878-.068 5.803-.2 8.692C179.972-77.33 98.912 0 0 0m0-387.433c-111.765 0-202.691 90.927-202.691 202.692C-202.691-72.977-111.765 17.951 0 17.951c52.482 0 102.255-19.981 140.152-56.263 37.776-36.166 59.909-84.788 62.32-136.908.146-3.166.219-6.369.219-9.521 0-111.765-90.927-202.692-202.691-202.692"
            clipPath="url(#a)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 219.832 96.695)"
          />
          <path
            d="M0 0c-4.498-9.902-19.665-46.034-19.665-82.8v-144.275c0-46.529 14.782-92.189 41.622-128.569a251.95 251.95 0 0 1 2.783-3.699c47.083-61.422 122.302-98.09 201.214-98.09h120.969v322.312c0 .275.013.551.038.825 1.417 15.347 4.704 58.33 2.924 72.744-4.749 38.444-14.627 54.861-17.681 59.142-143.056 34.229-168.577-187.323-168.577-187.323S144.3 18.368 0 0m355.898-475.383H225.954c-41.464 0-82.972 9.502-120.036 27.481-37.397 18.139-70.394 44.985-95.423 77.636a282.65 282.65 0 0 0-2.979 3.96c-29.105 39.45-45.132 88.894-45.132 139.231V-82.8c0 47.278 21.867 91.607 22.798 93.47a8.967 8.967 0 0 0 3.942 3.98C25.912 33.47 69.77 30.981 115.957 7.456c24.081-12.264 42.742-27.083 51.966-35.061 32.337 28.94 66.015 45.994 100.213 50.725 32.115 4.442 57.607-2.977 73.338-9.985a8.991 8.991 0 0 0 2.763-1.923c1.728-1.767 17.064-18.755 23.462-70.564 2.301-18.626-2.316-70.579-2.826-76.179v-330.877a8.976 8.976 0 0 0-8.975-8.975"
            clipPath="url(#b)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 -.268 232.617)"
          />
          <path
            d="M0 0c0-26.346-21.357-47.703-47.703-47.703-26.346 0-47.704 21.357-47.704 47.703 0 26.346 21.358 47.704 47.704 47.704S0 26.346 0 0"
            clipPath="url(#c)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 437.371 323.733)"
          />
          <path
            d="M0 0c0-26.346 21.357-47.703 47.703-47.703 26.346 0 47.704 21.357 47.704 47.703 0 26.346-21.358 47.704-47.704 47.704S0 26.346 0 0"
            clipPath="url(#d)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 -1.625 323.733)"
          />
          <path
            d="m0 0 32.865 52.729L0 84.07l-.556.53-.554-.53-32.866-31.341L-1.11 0l.554-.891Z"
            clipPath="url(#e)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 218.613 484.407)"
          />
          <path
            d="m0 0 57.993 40.799 51.505 14.057 50.755-6.39-4.028 23.538-65.571 54.619L9.64 150.259l-96.456-18.656s-39.763-27.881-48.366-34.785c-8.601-6.902-28.152-31.232-29.656-33.535-1.503-2.303-5.688-14.817-5.688-14.817l75.666 5.516s6.277.662 17.777-5.516c11.501-6.178 58.422-28.074 59.986-30.421C-15.533 15.699 0 0 0 0"
            clipPath="url(#f)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 223.7 285.625)"
          />
          <path
            d="M0 0c-17.73-92.633-41.81-164.771-101.96-187.038-8.836-3.271-35.729-11.521-44.489-17.712l-57.471-11.769.472-37.515c-3.775-68.482 15.439-113.237 45.138-139.129 17.015-14.833 39.47-22.869 62.65-22.868 22.525.001 58.365.009 83.139.003C0-416.031 0-405.881 0-401.24Z"
            clipPath="url(#g)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 489.794 311.752)"
          />
          <path
            d="m0 0-71.226-64.375s-60.14 15.222-98.672-24.593c-37.414-38.659-53.713-92.681-53.713-92.681l-16.192 15.441s19.978 50.702 41.654 75.941c21.674 25.241 41.152 45.672 73.622 51.371 29.544 5.184 48.168-.913 48.168-.913L-32.833 0Z"
            clipPath="url(#h)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 537.61 149.997)"
          />
          <path
            d="m0 0-14.774-24.107s-54.556 6.112-96.453-22.088c-28.462-19.158-62.911-75.883-62.911-75.883v37.157c6.126 11.007 15.587 24.344 28.581 39.474 21.676 25.24 52.5 40.077 73.502 43.2C-59.078-.317-43.393.435-32.832 0-26.301-.269 0 0 0 0"
            clipPath="url(#i)"
            style={{
              fill: '#f06a0f',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 450.057 149.997)"
          />
          <path
            d="m0 0-43.526-39.809s-51.462 9.145-96.371-26.146c-41.508-32.618-67.073-100.874-67.073-100.874v44.13s13.484 29.483 35.159 54.722c21.676 25.241 52.499 40.078 73.501 43.201 21.002 3.123 49.062 4.672 49.062 4.672L-32.832 0Z"
            clipPath="url(#j)"
            style={{
              fill: '#fec200',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 493.833 149.997)"
          />
          <path
            d="m0 0 71.226-64.375s60.14 15.222 98.672-24.593c37.415-38.659 53.713-92.681 53.713-92.681l16.193 15.441s-19.979 50.702-41.655 75.941c-21.674 25.241-41.152 45.672-73.622 51.371-29.544 5.184-48.168-.913-48.168-.913L32.833 0Z"
            clipPath="url(#k)"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 -101.865 149.997)"
          />
          <path
            d="m0 0 14.774-24.107s54.556 6.112 96.453-22.088c28.462-19.158 62.912-75.883 62.912-75.883v37.157c-6.127 11.007-15.588 24.344-28.582 39.474-21.676 25.24-52.5 40.077-73.502 43.2C59.078-.317 43.393.435 32.832 0 26.301-.269 0 0 0 0"
            clipPath="url(#l)"
            style={{
              fill: '#f06a0f',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 -14.311 149.997)"
          />
          <path
            d="m0 0 43.526-39.809s51.462 9.145 96.371-26.146c41.508-32.618 67.074-100.874 67.074-100.874v44.13s-13.485 29.483-35.16 54.722c-21.675 25.241-52.499 40.078-73.501 43.201-21.002 3.123-49.062 4.672-49.062 4.672L32.832 0Z"
            clipPath="url(#m)"
            style={{
              fill: '#fec200',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.05,
            }}
            transform="matrix(1.33333 0 0 -1.33333 -58.087 149.997)"
          />
        </g>
      </g>
    </svg>
  )
}

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
}

export default Logo
