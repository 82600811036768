import React from 'react'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import { cilCreditCard, cilUser, cilAccountLogout } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { logout } from '../../services/auth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import avatar from '../../assets/images/avatar.png'
import I18nDropdown from '../I18nDropdown'
import { useSelector } from 'react-redux'

const AppHeaderDropdown = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const profile = useSelector((state) => state.profile)
  const handleLogout = () => {
    logout()
    navigate('/login', { replace: true })
  }

  return (
    <div className="d-flex align-items-center">
      <I18nDropdown />
      <CDropdown variant="nav-item" alignment="end">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <CAvatar src={avatar} size="sm" />
        </CDropdownToggle>
        <CDropdownMenu>
          {/* <CDropdownItem href="/#/profile">
            <CIcon icon={cilUser} className="me-2" />
            {t('Profile')}
          </CDropdownItem>
          <CDropdownDivider /> */}
          <CDropdownItem onClick={() => handleLogout()} style={{ cursor: 'pointer' }}>
            <CIcon icon={cilAccountLogout} className="me-2" />
            {t('Logout')}
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </div>
  )
}

export default AppHeaderDropdown
